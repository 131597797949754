<template>
  <v-app>
    <container :is="layout">
      <main>
        <router-view/>
      </main>
    </container>
  </v-app>
</template>

<script>

import DefaultLayout from '@/layouts/DefaultLayout'
import StatementLayout from '@/layouts/StatementLayout'
import EmptyLayout from './layouts/EmptyLayout'
//

export default {
  name: 'App',

  components: {
    DefaultLayout,
    EmptyLayout,
    StatementLayout
  },

  computed: {
    layout () {
      return this.$route.meta.layout || 'empty-layout'
    }
  },

  data: () => ({
    //
  })
}
</script>

<style lang="sass">
  @import "./assets/css/style"
  //$body-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
  //$font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important
  //.v-application
  //  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important
</style>
