import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/frame',
    name: 'Frame',
    meta: {
      layout: 'empty-layout'
    },
    component: () => import('../views/member/MemberListBySro')
  },
  {
    path: '/reestr/clients/:id/members',
    name: 'ReestrFrame',
    meta: {
      layout: 'empty-layout'
    },
    component: () => import('../views/member/MemberListBySro')
  },
  {
    path: '/reestr/members/:id',
    name: 'FrameMemberItem',
    meta: {
      layout: 'empty-layout'
    },
    component: () => import('../views/member/MemberItem')
  },
  {
    path: '/test',
    name: 'SroListTest',
    meta: {
      layout: 'default-layout'
    },
    component: () => import('../views/sro/SroListTest')
  },
  {
    path: '/',
    name: 'SroList',
    meta: {
      layout: 'default-layout'
    },
    component: () => import('../views/sro/SroList')
  },
  {
    path: '/sro/:id',
    name: 'SroItem',
    meta: {
      layout: 'default-layout'
    },
    component: () => import('../views/sro/SroItem')
  },
  {
    path: '/sro/all/member/list',
    name: 'MemberListByAllSro',
    meta: {
      layout: 'default-layout'
    },
    component: () => import('../views/member/MemberListByAllSro')
  },
  {
    path: '/sro/:id/member/list',
    name: 'MemberListBySro',
    meta: {
      layout: 'default-layout'
    },
    component: () => import('../views/member/MemberListBySro')
  },
  {
    path: '/member/:id',
    name: 'MemberItem',
    meta: {
      layout: 'default-layout'
    },

    component: () => import('../views/member/MemberItem')
  },
  {
    path: '/statement/:token',
    name: 'StatementItem',
    meta: {
      layout: 'statement-layout'
    },
    component: () => import('../views/statement/StatementItem')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
