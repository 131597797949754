<template
>
  <div class="contacts">
      <div class="close">
        <v-icon class="chip__bn"
                style="min-width: 20px"
                @click="hideWindow"
        >mdi-close
        </v-icon>
      </div>
      <div class="data">
        Для связи со специалистом, курирующим федеральный округ, необходимо после номера
        <b class="text-danger"> +7 (495) 987-31-50 </b>
        набрать в тональном режиме соответствующий добавочный номер из списка:
      </div>
      <table>
<!--        <tr>-->
<!--          <td>-->
<!--            <div class="name">Начальник Управления</div>-->
<!--          </td>-->
<!--          <td>-->
<!--              <div class="details">-->
<!--                Маркелов Г.А.<br>-->
<!--                (доб. <b class="text-danger">143</b>)<br>-->
<!--                <a href="mailto:g.markelov@nostroy.ru">g.markelov@nostroy.ru</a>-->
<!--              </div>-->
<!--          </td>-->
<!--        </tr>-->
        <tr>
          <td>
            <div class="name">ЮФО, ЦФО, ДФО</div>
          </td>
          <td>
              <div class="details">
                Бородина О.И.<br>
                (доб. <b class="text-danger">142</b>)<br>
                <a href="mailto:o.borodina@nostroy.ru">o.borodina@nostroy.ru</a>
              </div>
          </td>
        </tr>
        <tr >
          <td >
            <div class="name">СФО, СКФО</div>
          </td>
          <td>
            <div class="details">
              Воробьев В.В.<br>
              (доб. <b class="text-danger">157</b>)<br>
              <a href="mailto:v.vorobiev@nostroy.ru">v.vorobiev@nostroy.ru</a>
            </div>
          </td>
        </tr>
        <tr >
          <td >
            <div class="name">Москва, Санкт-Петербург</div>
          </td>
          <td>
            <div class="details">
              Сулейманова М.М.<br>
              (доб. <b class="text-danger">218</b>)<br>
              <a href="mailto:m.suleymanova@nostroy.ru">m.suleymanova@nostroy.ru</a>
            </div>
          </td>
        </tr>
        <tr >
          <td >
            <div class="name">СЗФО, ПФО, УФО</div>
          </td>
          <td>
            <div class="details">
              Таратина Е.А.<br>
              (доб. <b class="text-danger">206</b>)<br>
              <a href="mailto:a.stepanova@nostroy.ru">e.taratina@nostroy.ru</a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="name">Техническая поддержка</div>
          </td>
          <td class="details">
            <div><b class="text-danger">+7 (812) 389-22-56</b></div></td>
        </tr>
      </table>
  </div>
</template>

<script>

export default {
  name: 'Conctacts',
  props: {
    isContactsVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hideWindow () {
      this.$emit('hide-contacts', false)
    }
  }
}
</script>
