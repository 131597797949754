<template>
  <div>
    <header class="header">
      <div
        class="header__logo pointer"
        @click="goToSroList"
      >
        <svg
          id="logo.svg" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 328.82 211.42">
          <defs>
            <linearGradient id="Безымянный_градиент_4" x1="178.47" y1="55.45" x2="204.85" y2="15.19"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.09" stop-color="#fff"/>
              <stop offset="0.15" stop-color="#f9f9f9"/>
              <stop offset="0.23" stop-color="#eaeae9"/>
              <stop offset="0.32" stop-color="#cfcfcf"/>
              <stop offset="0.42" stop-color="#ababaa"/>
              <stop offset="0.52" stop-color="#7b7b7a"/>
              <stop offset="0.62" stop-color="#434341"/>
              <stop offset="0.69" stop-color="#1d1d1b"/>
              <stop offset="0.84" stop-color="#342d2c"/>
            </linearGradient>
            <linearGradient id="Безымянный_градиент_3" x1="-3105.15" y1="45.4" x2="-3078.14" y2="5.26"
                            gradientTransform="matrix(-1, 0, 0, 1, -2963.01, 0)" gradientUnits="userSpaceOnUse">
              <stop offset="0.22" stop-color="#fff"/>
              <stop offset="0.37" stop-color="#c5c3c3"/>
              <stop offset="0.6" stop-color="#787372"/>
              <stop offset="0.77" stop-color="#474040"/>
              <stop offset="0.84" stop-color="#342d2c"/>
              <stop offset="1" stop-color="#1d1d1b"/>
            </linearGradient>
            <linearGradient id="Безымянный_градиент_2" x1="163.88" y1="30.42" x2="163.88" y2="121.01"
                            gradientUnits="userSpaceOnUse">
              <stop offset="0.05" stop-color="#fff"/>
              <stop offset="0.07" stop-color="#fefefe"/>
              <stop offset="0.15" stop-color="#f8f7f7"/>
              <stop offset="0.26" stop-color="#e6e5e5"/>
              <stop offset="0.39" stop-color="#c9c7c6"/>
              <stop offset="0.54" stop-color="#a09d9c"/>
              <stop offset="0.69" stop-color="#6c6767"/>
              <stop offset="0.84" stop-color="#342d2c"/>
              <stop offset="1" stop-color="#1d1d1b"/>
            </linearGradient>
          </defs>
          <g class="cls-1">
            <g id="Слой_2" data-name="Слой 2">
              <g id="RGB">
                <g id="RUS___Full___RGB">
                  <path class="cls-2" d="M15.65,172.74V162.66h2.8v22.49h-2.8v-9.73H2.86v9.73H.05V162.66H2.86v10.08Z"/>
                  <path class="cls-2"
                        d="M47.83,185.15H45.06l-2.33-5.87H32l-2.29,5.87H27l8.36-22.49h4.15Zm-6.12-8.55L37.4,165.28,33.06,176.6Z"/>
                  <path class="cls-2" d="M74.79,182.47h3.76v7H75.74v-4.3H56.38V162.66h2.81v19.81H72V162.66h2.81Z"/>
                  <path class="cls-2"
                        d="M90.1,180.56l12.28-17.9h2.74v22.49h-2.74V167.22L90.1,185.15H87.35V162.66H90.1Z"/>
                  <path class="cls-2"
                        d="M132.27,183.55q-2.46,2-7.37,2c-3.28,0-5.73-.67-7.37-2a7,7,0,0,1-2.46-5.71v-7.72a7.1,7.1,0,0,1,2.49-5.83c1.66-1.36,4.1-2,7.34-2s5.67.68,7.32,2a7.12,7.12,0,0,1,2.47,5.83v7.72A7,7,0,0,1,132.27,183.55Zm-7.37-.67c2.4,0,4.16-.44,5.29-1.34a4.84,4.84,0,0,0,1.69-4v-7.05a5.05,5.05,0,0,0-1.66-4.16c-1.1-.9-2.88-1.36-5.32-1.36s-4.23.46-5.35,1.36a5.06,5.06,0,0,0-1.67,4.16v7.05a4.88,4.88,0,0,0,1.67,4C120.67,182.44,122.45,182.88,124.9,182.88Z"/>
                  <path class="cls-2"
                        d="M160.24,172.74V162.66h2.81v22.49h-2.81v-9.73H147.45v9.73h-2.81V162.66h2.81v10.08Z"/>
                  <path class="cls-2"
                        d="M192.43,185.15h-2.78l-2.33-5.87H176.64l-2.3,5.87h-2.78l8.36-22.49h4.15Zm-6.13-8.55L182,165.28l-4.33,11.32Z"/>
                  <path class="cls-2"
                        d="M204.64,162.66h14v22.49H215.8V165.31h-8.61l-1.43,12.53a9.85,9.85,0,0,1-2,5.52,5.82,5.82,0,0,1-4.56,1.79H198.1v-2.68h.58a3.74,3.74,0,0,0,3-1.15,6.53,6.53,0,0,0,1.26-3.67Z"/>
                  <path class="cls-2"
                        d="M232,171.3h7.81a8.06,8.06,0,0,1,5.4,1.63,5.7,5.7,0,0,1,1.88,4.53V179a5.65,5.65,0,0,1-1.88,4.56,8.16,8.16,0,0,1-5.43,1.6H229.2V162.66H232ZM244.29,179v-1.53q0-3.48-4.34-3.48H232v8.49h8Q244.29,182.47,244.29,179Z"/>
                  <path class="cls-2" d="M271.82,172.74V162.66h2.8v22.49h-2.8v-9.73H259v9.73h-2.8V162.66H259v10.08Z"/>
                  <path class="cls-2"
                        d="M301.13,183.55c-1.64,1.34-4.09,2-7.37,2s-5.73-.67-7.37-2a7,7,0,0,1-2.45-5.71v-7.72a7.1,7.1,0,0,1,2.49-5.83c1.65-1.36,4.1-2,7.33-2s5.67.68,7.32,2a7.12,7.12,0,0,1,2.48,5.83v7.72A7,7,0,0,1,301.13,183.55Zm-7.37-.67c2.4,0,4.17-.44,5.3-1.34a4.84,4.84,0,0,0,1.69-4v-7.05a5.08,5.08,0,0,0-1.66-4.16c-1.11-.9-2.88-1.36-5.33-1.36s-4.22.46-5.34,1.36a5,5,0,0,0-1.68,4.16v7.05a4.86,4.86,0,0,0,1.68,4C289.54,182.44,291.32,182.88,293.76,182.88Z"/>
                  <path class="cls-2"
                        d="M328.82,162.66v2.65H315.68v7.43h11.7v2.68h-11.7v7.05h13.14v2.68H312.87V162.66Z"/>
                  <path class="cls-2"
                        d="M4.87,209.59a5.79,5.79,0,0,1-3.61-1A3.26,3.26,0,0,1,0,205.87v-3.7a3.33,3.33,0,0,1,1.25-2.76,5.71,5.71,0,0,1,3.6-1,5.73,5.73,0,0,1,3.61,1,3.31,3.31,0,0,1,1.26,2.76v3.7a3.26,3.26,0,0,1-1.26,2.74A5.75,5.75,0,0,1,4.87,209.59Zm0-1.73c2,0,3-.72,3-2.17v-3.34c0-1.46-1-2.2-3-2.2s-3,.74-3,2.2v3.34C1.87,207.14,2.86,207.86,4.85,207.86Z"/>
                  <path class="cls-2"
                        d="M18.37,202.6h3.32a3.87,3.87,0,0,1,2.66.83,2.91,2.91,0,0,1,.95,2.29v.53a2.87,2.87,0,0,1-1,2.29,3.88,3.88,0,0,1-2.66.84H16.5V198.64h7.94v1.73H18.37Zm5.06,3.71v-.65a1.21,1.21,0,0,0-.41-1,1.89,1.89,0,0,0-1.22-.34H18.37v3.32H21.8C22.89,207.65,23.43,207.2,23.43,206.31Z"/>
                  <path class="cls-2"
                        d="M35.31,202.6h3.32a3.86,3.86,0,0,1,2.66.83,2.87,2.87,0,0,1,1,2.29v.53a2.89,2.89,0,0,1-1,2.29,3.92,3.92,0,0,1-2.67.84H33.44v-9H31.17v-1.73h4.14Zm5.07,3.71v-.65a1.22,1.22,0,0,0-.42-1,1.89,1.89,0,0,0-1.22-.34H35.31v3.32h3.44C39.84,207.65,40.38,207.2,40.38,206.31Z"/>
                  <path class="cls-2" d="M56.67,198.64v1.73h-6v2.89H56V205H50.72v2.66h6v1.73H48.85V198.64Z"/>
                  <path class="cls-2"
                        d="M72.74,198.64v9h1.69v3.77H72.56v-2H64.33v2H62.46v-3.77H63a1.58,1.58,0,0,0,1.28-.51,3,3,0,0,0,.56-1.62l.78-6.88Zm-6.69,9h4.83v-7.28H67.25l-.57,5.21A5.25,5.25,0,0,1,66.05,207.65Z"/>
                  <path class="cls-2" d="M82.51,206.37l5.3-7.73h1.82v10.74H87.81v-7.74l-5.3,7.74H80.69V198.64h1.82Z"/>
                  <path class="cls-2" d="M103.91,203.26v-4.62h1.87v10.74h-1.87V205H98.48v4.39H96.62V198.64h1.86v4.62Z"/>
                  <path class="cls-2" d="M120.6,198.64v1.73h-5.95v2.89H120V205h-5.31v2.66h5.95v1.73h-7.81V198.64Z"/>
                  <path class="cls-2" d="M134.51,203.26v-4.62h1.86v10.74h-1.86V205h-5.43v4.39h-1.87V198.64h1.87v4.62Z"/>
                  <path class="cls-2" d="M145.2,206.37l5.29-7.73h1.82v10.74h-1.82v-7.74l-5.29,7.74h-1.82V198.64h1.82Z"/>
                  <path class="cls-2" d="M167.12,198.64v1.73h-6v2.89h5.31V205h-5.31v2.66h6v1.73h-7.81V198.64Z"/>
                  <path class="cls-2"
                        d="M191.39,208.6a6.91,6.91,0,0,1-7.13,0,3.26,3.26,0,0,1-1.25-2.73v-3.73a3.29,3.29,0,0,1,1.25-2.74,5.71,5.71,0,0,1,3.56-1,5.64,5.64,0,0,1,3.55,1,3.29,3.29,0,0,1,1.24,2.74v.55h-2v-.37c0-1.44-.94-2.17-2.81-2.17s-2.94.73-2.94,2.17v3.37c0,1.44,1,2.15,2.94,2.15s2.81-.71,2.81-2.15v-.38h2v.56A3.29,3.29,0,0,1,191.39,208.6Z"/>
                  <path class="cls-2" d="M197.91,198.64h9.16v1.73h-3.64v9h-1.87v-9h-3.65Z"/>
                  <path class="cls-2"
                        d="M212.59,198.64h5.17a3.88,3.88,0,0,1,2.67.83,2.88,2.88,0,0,1,1,2.29v.79a2.87,2.87,0,0,1-1,2.29,3.86,3.86,0,0,1-2.65.83h-3.32v3.71h-1.87Zm6.93,4v-.91c0-.89-.54-1.33-1.64-1.33h-3.42V204h3.42a1.9,1.9,0,0,0,1.23-.35A1.23,1.23,0,0,0,219.52,202.61Z"/>
                  <path class="cls-2"
                        d="M232.34,209.59a5.79,5.79,0,0,1-3.61-1,3.26,3.26,0,0,1-1.26-2.74v-3.7a3.33,3.33,0,0,1,1.25-2.76,7.06,7.06,0,0,1,7.21,0,3.31,3.31,0,0,1,1.26,2.76v3.7a3.26,3.26,0,0,1-1.26,2.74A5.75,5.75,0,0,1,232.34,209.59Zm0-1.73c2,0,3-.72,3-2.17v-3.34c0-1.46-1-2.2-3-2.2s-3,.74-3,2.2v3.34C229.34,207.14,230.33,207.86,232.32,207.86Z"/>
                  <path class="cls-2" d="M245.79,206.37l5.3-7.73h1.82v10.74h-1.82v-7.74l-5.3,7.74H244V198.64h1.82Z"/>
                  <path class="cls-2" d="M259,198.64h9.16v1.73h-3.64v9h-1.87v-9H259Z"/>
                  <path class="cls-2" d="M282.12,198.64v1.73h-5.95v2.89h5.31V205h-5.31v2.66h5.95v1.73h-7.81V198.64Z"/>
                  <path class="cls-2"
                        d="M291.16,198.64h7.16v10.74h-1.87v-9h-3.62l-.58,5.21a5.29,5.29,0,0,1-1,2.89,3,3,0,0,1-2.33.91h-.65v-1.73h.29a1.59,1.59,0,0,0,1.28-.51,3.07,3.07,0,0,0,.55-1.62Z"/>
                  <path class="cls-2" d="M313.12,198.64v1.73h-5.94v2.89h5.31V205h-5.31v2.66h5.94v1.73h-7.81V198.64Z"/>
                  <path class="cls-2"
                        d="M321.56,206.37l5.29-7.73h1.82v10.74h-1.82v-7.74l-5.29,7.74h-1.83V198.64h1.83Zm4.48-9.05a2.75,2.75,0,0,1-1.85.63,2.78,2.78,0,0,1-1.85-.63,2.07,2.07,0,0,1-.74-1.63h1.15a1,1,0,0,0,.41.83,1.88,1.88,0,0,0,2.07,0,1,1,0,0,0,.41-.83h1.15A2.05,2.05,0,0,1,326,197.32Z"/>
                  <polygon class="cls-2"
                           points="29.27 94.89 29.27 112.55 11.28 112.55 11.28 94.89 0.12 94.89 0.12 146 11.28 146 11.28 122.63 29.27 122.63 29.27 146 40.43 146 40.43 94.89 29.27 94.89"/>
                  <path class="cls-2"
                        d="M85.78,142.61q-5.55,4.47-15.26,4.47t-15.34-4.47a14.27,14.27,0,0,1-5.61-11.87V110.15a14.39,14.39,0,0,1,5.58-12q5.58-4.4,15.29-4.39t15.3,4.39a14.39,14.39,0,0,1,5.58,12v20.59a14.47,14.47,0,0,1-5.54,11.87M70.44,137q9.72,0,9.72-6.77V110.65q0-6.77-9.64-6.76-9.79,0-9.8,6.76v19.58q0,6.78,9.72,6.77"/>
                  <path class="cls-2"
                        d="M120.49,147.08q-9.57,0-15.12-4.46a14.3,14.3,0,0,1-5.61-11.88V110.15a14.53,14.53,0,0,1,5.54-12q5.48-4.4,15.12-4.39,9.49,0,15,4.39a14.42,14.42,0,0,1,5.55,12v4.61H129.42v-4.11a5.92,5.92,0,0,0-2.31-5c-1.53-1.18-3.74-1.76-6.62-1.76a12,12,0,0,0-7,1.79,5.77,5.77,0,0,0-2.52,5v19.58a5.78,5.78,0,0,0,2.48,5,12,12,0,0,0,7,1.76,10.73,10.73,0,0,0,6.66-1.8,5.91,5.91,0,0,0,2.34-5v-4.1H141v4.61a14.46,14.46,0,0,1-5.47,11.88q-5.55,4.45-15,4.46"/>
                  <polygon class="cls-2"
                           points="146.24 94.89 184.75 94.89 184.75 104.97 171.15 104.97 171.15 146 159.99 146 159.99 104.97 146.24 104.97 146.24 94.89"/>
                  <path class="cls-2"
                        d="M275.19,142.61q-5.55,4.47-15.26,4.47t-15.33-4.47A14.25,14.25,0,0,1,239,130.74V110.15a14.39,14.39,0,0,1,5.58-12q5.58-4.4,15.3-4.39t15.3,4.39a14.41,14.41,0,0,1,5.57,12v20.59a14.45,14.45,0,0,1-5.54,11.87M259.86,137q9.72,0,9.72-6.77V110.65q0-6.77-9.65-6.76t-9.79,6.76v19.58q0,6.78,9.72,6.77"/>
                  <polygon class="cls-2"
                           points="299.44 126.99 318.31 94.89 328.67 94.89 328.67 146 318.31 146 318.31 113.82 299.44 146 289.08 146 289.08 94.89 299.44 94.89 299.44 126.99"/>
                  <path class="cls-2"
                        d="M192.57,94.9h21.52q7.49,0,12.16,4a13.85,13.85,0,0,1,4.61,10.87v9.5q0,6.84-4.53,10.79-4.68,4.11-12.17,4.11H203.73V146H192.57Zm27.13,24.9V109.29a4,4,0,0,0-1.29-3.16,5.13,5.13,0,0,0-3.53-1.16H203.73v19.15h11.08a5.24,5.24,0,0,0,3.56-1.15,4,4,0,0,0,1.33-3.17"/>
                  <rect class="cls-3" x="298.48" y="77.71" width="20.86" height="8.83"/>
                  <polygon class="cls-4" points="185.83 12.67 163.88 0 29.27 77.71 73.18 77.71 185.83 12.67"/>
                  <polygon class="cls-5" points="95.25 77.71 196.87 19.05 185.83 12.67 73.18 77.71 95.25 77.71"/>
                  <polygon class="cls-6" points="141.31 77.71 219.9 32.34 196.87 19.05 95.25 77.71 141.31 77.71"/>
                  <polygon class="cls-7" points="219.9 32.34 141.31 77.71 150.33 77.71 224.41 34.95 219.9 32.34"/>
                  <polygon class="cls-8" points="253.31 51.63 224.41 34.95 150.33 77.71 208.14 77.71 253.31 51.63"/>
                  <polygon class="cls-3" points="253.31 51.63 208.14 77.71 298.48 77.71 253.31 51.63"/>
                  <polygon class="cls-9" points="29.27 77.71 163.88 0 298.48 77.71 29.27 77.71"/>
                  <polygon class="cls-10" points="298.48 77.71 163.88 0 29.27 77.71 298.48 77.71"/>
                  <polygon class="cls-11" points="298.48 77.71 163.88 0 29.27 77.71 298.48 77.71"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="header__title">
        <div class="header__title_title">
          <div v-if="this.$route.path.match(/^\/$|^\/sro\/\d+$/)">
            Саморегулируемые организации в области строительства, реконструкции, <br>
            капитального ремонта, сноса объектов капитального строительства
          </div>
          <div v-else>
            Единый реестр сведений о членах саморегулируемых организаций <br>
            в области строительства, реконструкции, капитального ремонта, <br>
            сноса объектов капитального строительства и их обязательствах
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 430px
      ">
        <div>
          <div
            class="d-flex justify-end"
          >
            <v-btn-toggle
              class="toggles"
              dense
              borderless
              shaped
              mandatory
              :value="currentList"
            >
              <v-btn
                color="secondary"
                @click="selectSroList"
              >
                Реестр СРО
              </v-btn>
              <v-btn
                color="secondary"
                @click="selectMemberList"
              >
                Реестр членов СРО
              </v-btn>
              <v-btn
                color="secondary"
                @click="showRequestExtract"
              >
                Запрос выписки
              </v-btn>
              <v-btn
                color="secondary"
                @click="showContacts"
              >
                Контакты
              </v-btn>
            </v-btn-toggle>
          </div>
<!--          <v-icon title="twitter">mdi-twitter</v-icon>-->
<!--          <v-icon title="facebook">mdi-facebook</v-icon>-->
<!--          <v-icon title="youtube">mdi-youtube</v-icon>-->
        </div>
        <div v-show="isContactsVisibleLocal"
          class="main-contacts"
        >
        <contacts @hide-contacts="hideContacts"/>
        </div>
      </div>

    </header>
    <main class="max-width">
      <v-container
        class="py-0"
        fluid
      >
        <router-view/>
      </v-container>
    </main>
  </div>
</template>

<script>
import Contacts from '../components/contacts/Contacts'

export default {
  name: 'default-layout',
  components: {
    Contacts
  },

  props: {
    isContactsVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isContactsVisibleLocal: this.isContactsVisible
    }
  },
  methods: {
    goToSroList () {
      if (this.$route.name !== 'SroList') {
        this.$router.push({ name: 'SroList' })
      }
    },
    selectSroList () {
      this.goToSroList()
    },
    selectMemberList () {
      if (this.$route.name !== 'MemberListByAllSro') {
        this.$router.push({ name: 'MemberListByAllSro' })
      }
    },
    showRequestExtract () {
      window.open(
        'https://nostroy.ru/abstract',
        '_blank'
      )
    },
    showContacts () {
      this.isContactsVisibleLocal = true
    },
    hideContacts () {
      this.isContactsVisibleLocal = false
    }
  },
  computed: {
    currentList () {
      if (this.$route.name === 'MemberListByAllSro') {
        return 1
      }

      if (this.$route.name === 'SroList') {
        return 0
      }

      return null
    }
  }
}
</script>

<style>
.cls-bl {
  fill:#3067ba;fill-opacity:1;fill-rule:nonzero;stroke:none;
}
.cls-2 {
  fill:#e91944;fill-opacity:1;fill-rule:nonzero;stroke:none;
}
.cls-3 {
  fill:#c8c7c7;fill-opacity:1;fill-rule:nonzero;stroke:none;
}
.cls-4 {
  fill:#deddde;fill-opacity:1;fill-rule:nonzero;stroke:none;
}
.cls-5 {
  fill:#3067ba;fill-opacity:1;fill-rule:nonzero;stroke:none;
}
.cls-6 {
  fill:#656263;fill-opacity:1;fill-rule:nonzero;stroke:none;
}
.v-item-group {
  border-radius: 4px !important;
}

</style>
