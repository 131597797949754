<template>
  <div>
    <main class="max-width">
      <v-container
        class="py-0"
        fluid
      >
        <router-view/>
      </v-container>
    </main>
  </div>
</template>

<script>
export default {
  name: 'empty-layout',
  methods: {
    goToSroList () {
      if (this.$route.name !== 'SroList') {
        this.$router.push({ name: 'SroList' })
      }
    },
    selectSroList () {
      this.goToSroList()
    },
    selectMemberList () {
      if (this.$route.name !== 'MemberListByAllSro') {
        this.$router.push({ name: 'MemberListByAllSro' })
      }
    }
  },
  computed: {
    currentList () {
      if (this.$route.name === 'MemberListByAllSro') {
        return 1
      }

      if (this.$route.name === 'SroList') {
        return 0
      }

      return null
    }
  }
}
</script>

<style>
.cls-1 {
  isolation: isolate;
}

.cls-2 {
  fill: #505863;
}

.cls-3 {
  fill: #d8101c;
}

.cls-4 {
  fill: #f0eef0;
}

.cls-5 {
  fill: #dfe1e8;
}

.cls-6 {
  fill: #dbdaef;
}

.cls-7 {
  fill: #8682bd;
}

.cls-8 {
  fill: #4b509e;
}

.cls-10, .cls-11, .cls-9 {
  mix-blend-mode: multiply;
  opacity: 0.07;
}

.cls-9 {
  fill: url(#Безымянный_градиент_4);
}

.cls-10 {
  fill: url(#Безымянный_градиент_3);
}

.cls-11 {
  fill: url(#Безымянный_градиент_2);
}
.v-item-group {
  border-radius: 4px !important;
}

</style>
